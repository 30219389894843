<script setup>
const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: {
        canonicalQueries: ['market'],
    },
});

useHead({
    script: [
        {
            children: `
        // On page load or when changing themes, best to add inline in 'head' to avoid FOUC
        if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark')
        }
        `,
        },
        {
            children: `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer?.push(arguments);
        }
        gtag('consent', 'update', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
            security_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
        });
        `,
        },
        {
            children: `
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-P8TQQDC');
        `,
        },
    ],
});
</script>

<template>
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
        <Head>
            <template v-for="link in head.link" :key="link.id">
                <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
            </template>
            <template v-for="meta in head.meta" :key="meta.id">
                <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
            </template>
        </Head>
        <Body class="bg-white dark:bg-midnight-blue">
            <!-- Google Tag Manager (noscript) -->
            <noscript
                ><iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-P8TQQDC"
                    height="0"
                    width="0"
                    style="display: none; visibility: hidden"
                ></iframe
            ></noscript>
            <!-- End Google Tag Manager (noscript) -->
            <div class="page">
                <TopNavigation />
                <slot />
                <Footer />
            </div>
        </Body>
    </Html>
</template>
